/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { brand } from './brand';
import type { card_payment_type } from './card_payment_type';
import type { machine_in_group } from './machine_in_group';
import type { object_link } from './object_link';
import type { price_list } from './price_list';
import type { product_matrix } from './product_matrix';
import type { source } from './source';
import type { vm_location_formats } from './vm_location_formats';
import type { vm_log } from './vm_log';
import type { vm_manufacturer } from './vm_manufacturer';
import type { vm_model } from './vm_model';

/**
 * Кофейный аппарат
 */
export type vending_machine_coffee = (base_entity & {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Название
     */
    name?: string;
    /**
     * Серийный номер
     */
    serial?: string;
    /**
     * Модель
     */
    readonly modelName?: string;
    readonly nameAndSerial?: string;
    /**
     * Состояние
     */
    readonly status: number;
    readonly cashAtTheMachine?: number;
    readonly change?: number;
    readonly cashPerDay?: number;
    readonly cashlessPerDay?: number;
    readonly ingredientFill?: number;
    readonly connectionLevel?: number;
    readonly downtime?: number;
    placement?: string;
    readonly company?: string;
    readonly networkState?: vending_machine_coffee.networkState;
    readonly coinAcceptorState?: number;
    readonly billAcceptorState?: vending_machine_coffee.billAcceptorState;
    readonly cashlessState?: vending_machine_coffee.cashlessState;
    readonly qrPaymentState?: vending_machine_coffee.qrPaymentState;
    readonly cashRegisterState?: vending_machine_coffee.cashRegisterState;
    readonly fundsInTheMachine?: number;
    readonly revenuePerDay?: number;
    vmGroups?: (machine_in_group | object_link);
    readonly search?: string;
    equipment?: vending_machine_coffee.equipment;
    /**
     * Действующая товарная матрица
     */
    readonly currentProductMatrix?: (product_matrix | object_link);
    /**
     * Примечание
     */
    comment?: string;
    readonly soldInADay?: number;
    readonly errors?: Array<vm_log>;
    /**
     * Количество ошибок
     */
    readonly errCount?: number;
    readonly wmGroupsName?: string;
    locationFormats?: Array<vm_location_formats>;
    vmManufacturer: (vm_manufacturer | object_link);
    vmModel?: (vm_model | object_link);
    /**
     * Формат локации
     */
    readonly locationFormatsText?: string;
    /**
     * Город
     */
    readonly city?: string;
    /**
     * Число знаков в ценах, обозначающих копейки
     */
    decimalPrecision?: number;
    /**
     * Время работы автомата
     */
    timeOfWork?: string;
    productMatrixs?: (product_matrix | object_link);
    /**
     * Назначена ли матрица
     */
    readonly isHasProductMatrix?: boolean;
    readonly actualPriceList?: (price_list | object_link);
    readonly isInLocation?: boolean;
    /**
     * Часовой пояс
     */
    timeZone?: number;
    isInLoyaltySystem?: boolean;
    InLoyaltySystemDate?: string;
    readonly serialAndAddress?: string;
    qrCode?: string;
    /**
     * Адрес
     */
    address?: string;
    /**
     * Адрес отображение
     */
    readonly addressView?: string;
    /**
     * Координаты
     */
    coordinates?: string;
    /**
     * Координаты отображение
     */
    readonly coordinatesView?: string;
    readonly vmType?: vending_machine_coffee.vmType;
    readonly isRemoved?: boolean;
    priceListTemplate?: (price_list | object_link);
    brand?: (brand | object_link);
    source?: (source | object_link);
    isInLeasing?: boolean;
    cardPaymentType?: (card_payment_type | object_link);
    royalty?: number;
    readonly currentPriceList?: (price_list | object_link);
    isBonusEnabled: boolean;
    erpAmount?: number;
    appAmount?: number;
    royaltyStartDatetime?: string;
    appStartDatetime?: string;
    erpStartDatetime?: string;
    readonly calculatedRoyaltyAmount?: number;
    readonly calculatedAppAmount?: number;
    readonly calculatedErpAmount?: number;
    readonly companyId?: string;
    readonly createDate?: string;
    readonly lkEnabled?: boolean;
    readonly isMarketplacePoint?: boolean;
    isDeactivated: boolean;
    deactivateDate?: string;
});

export namespace vending_machine_coffee {

    export enum networkState {
        '_-1' = -1,
        '_0' = 0,
        '_1' = 1,
    }

    export enum billAcceptorState {
        '_-1' = -1,
        '_0' = 0,
        '_1' = 1,
    }

    export enum cashlessState {
        '_-1' = -1,
        '_0' = 0,
        '_1' = 1,
    }

    export enum qrPaymentState {
        '_0' = 0,
        '_-1' = -1,
    }

    export enum cashRegisterState {
        '_-1' = -1,
        '_0' = 0,
        '_1' = 1,
    }

    export enum equipment {
        '_1' = 1,
        '_2' = 2,
        '_4' = 4,
        '_8' = 8,
        '_16' = 16,
    }

    export enum vmType {
        '_0' = 0,
        '_1' = 1,
    }


}

