import { EEntityList } from '@shared/models';
import { EntityModel } from '@shared/models/entity.models';
import { EEquipmentType } from '@shared/models/vm-payment-method.models';
import { EquipmentStateEnum } from '@shared/modules/grid-cards/modules/vending-machine-card/enums/equipment-state.enum';
import { company } from '@src/api';
import { DateTime } from 'luxon';

export enum EVMStatus {
  Online,
  Offline,
  Removed,
}

export class IVendingMachineBase extends EntityModel {
  id: string;
  name: string;
  displayName: string;
  status: EVMStatus;
  serial: string;
  modelName: string;
  addressView: string;
  royalty: number;
  appAmount: number;
  erpAmount: number;
  royaltyStartDatetime: DateTime | string;
  appStartDatetime: DateTime | string;
  erpStartDatetime: DateTime | string;
  address: string;
  coordinatesView: string;
  coordinates: string;
  vmType: number;
  isDeactivated: boolean;
  deactivateDate: DateTime | string;
  constructor(value?: IVendingMachineBase) {
    super(value);
    this.id = value?.id;
    this.name = value?.name;
    this.status = value?.status;
    this.address = value?.address;
    this.addressView = value?.addressView;
    this.royalty = value?.royalty;
    this.appAmount = value?.appAmount;
    this.erpAmount = value?.erpAmount;
    this.royaltyStartDatetime = value?.royaltyStartDatetime;
    this.appStartDatetime = value?.appStartDatetime;
    this.erpStartDatetime = value?.erpStartDatetime;
    this.serial = value?.serial;
    this.modelName = value?.modelName;
    this.coordinates = value?.coordinates;
    this.coordinatesView = value?.coordinatesView;
    this.displayName = value?.displayName;
    this.vmType = value?.vmType;
    this.isDeactivated = value?.isDeactivated;
    this.deactivateDate = value?.deactivateDate;
  }
}

export class VendingMachineInfoModel extends IVendingMachineBase {
  cashAtTheMachine: number;
  change: number;
  cashPerDay: number;
  cashlessPerDay: number;
  ingredientFill: number;
  constructor(value?: VendingMachineInfoModel) {
    super(value);
    this.cashAtTheMachine = value?.cashAtTheMachine;
    this.change = value?.change;
    this.cashPerDay = value?.cashPerDay;
    this.cashlessPerDay = value?.cashlessPerDay;
    this.status = value?.status;
    this.ingredientFill = value?.ingredientFill;
  }
}

export class VendingMachinePageInfoModel extends VendingMachineInfoModel {
  soldInADay: number;
  company: string;
  placement: string;
  comment: string;
  currentProductMatrix: {
    id: string;
    name: string;
  };
  currentPriceList: {
    id: string;
    displayName: string;
  };
  productMatrixs: {
    id: string;
    name: string;
  };
  priceListTemplate: {
    id: string;
    displayName: string;
  };
  locationFormats: { locationFormat: LocationFormat }[];
  constructor(value?: VendingMachinePageInfoModel) {
    super(value);
    this.soldInADay = value?.soldInADay;
    this.company = value?.company;
    this.placement = value?.placement;
    this.comment = value?.comment;
    this.currentProductMatrix = {
      id: value?.currentProductMatrix?.id,
      name: value?.currentProductMatrix?.name,
    };
    this.currentPriceList = {
      id: value?.currentPriceList?.id,
      displayName: value?.currentPriceList?.displayName,
    };
    this.productMatrixs = {
      id: value?.productMatrixs?.id,
      name: value?.productMatrixs?.name,
    };
    this.priceListTemplate = {
      id: value?.priceListTemplate?.id,
      displayName: value?.priceListTemplate?.displayName,
    };
    this.locationFormats = [
      {
        locationFormat: {
          id: value?.locationFormats?.[0]?.locationFormat?.id,
          name: value?.locationFormats?.[0]?.locationFormat?.name,
        },
      },
    ];
  }
}

export class VendingMachinePageStateModel extends IVendingMachineBase {
  ingredientFill: number;
  connectionLevel: number;
  downtime: number;
  networkState: EquipmentStateEnum;
  coinAcceptorState: EquipmentStateEnum;
  billAcceptorState: EquipmentStateEnum;
  cashlessState: EquipmentStateEnum;
  qrPaymentState: EquipmentStateEnum;
  cashRegisterState: EquipmentStateEnum;
  constructor(value?: VendingMachinePageStateModel) {
    super(value);
    this.ingredientFill = value?.ingredientFill;
    this.connectionLevel = value?.connectionLevel;
    this.downtime = value?.downtime;
    this.networkState = value?.networkState;
    this.coinAcceptorState = value?.coinAcceptorState;
    this.billAcceptorState = value?.billAcceptorState;
    this.cashlessState = value?.cashlessState;
    this.qrPaymentState = value?.qrPaymentState;
    this.cashRegisterState = value?.cashRegisterState;
  }
}

export class VendingMachineListModel extends IVendingMachineBase {
  placement: string;
  company: string;
  constructor(value?: VendingMachineListModel) {
    super(value);
    this.placement = value?.placement;
    this.company = value?.company;
  }
}

export class VendingMachineStateModel extends IVendingMachineBase {
  connectionLevel: number;
  downtime: number;
  networkState: EquipmentStateEnum;
  coinAcceptorState: EquipmentStateEnum;
  billAcceptorState: EquipmentStateEnum;
  cashlessState: EquipmentStateEnum;
  qrPaymentState: EquipmentStateEnum;
  cashRegisterState: EquipmentStateEnum;
  constructor(value?: VendingMachineStateModel) {
    super(value);
    this.connectionLevel = value?.connectionLevel;
    this.downtime = value?.downtime;
    this.networkState = value?.networkState;
    this.coinAcceptorState = value?.coinAcceptorState;
    this.billAcceptorState = value?.billAcceptorState;
    this.cashlessState = value?.cashlessState;
    this.qrPaymentState = value?.qrPaymentState;
    this.cashRegisterState = value?.cashRegisterState;
  }
}

export class VendingMachineGetModel extends IVendingMachineBase {
  vmManufacturer: {
    id: number;
    name: string;
  };
  brand: {
    id: number;
    name: string;
  };
  cardPaymentType: {
    id: number;
    name: string;
  };
  source: {
    id: number;
    name: string;
  };
  qrCode: string;
  vmModel: {
    id: number;
    name: string;
  };
  company: company;
  // productMatrix: ProductMatrixModel;
  productMatrixs: {
    id: string;
    name: string;
  };
  priceListTemplate: {
    id: string;
    name: string;
  };
  locationFormats: VmLocationFormat[];
  placement: string;
  comment: string;
  timeOfWork: string;
  equipment: number;
  decimalPrecision: number;
  isInLoyaltySystem: boolean;
  isInLeasing: boolean;
  isBonusEnabled: boolean;
  timeZone: number;
  serial: string;
  constructor(value?: VendingMachineGetModel) {
    super(value);
    this.vmManufacturer = {
      id: value?.vmManufacturer?.id,
      name: value?.vmManufacturer?.name,
    };
    this.brand = {
      id: value?.brand?.id,
      name: value?.brand?.name,
    };
    this.cardPaymentType = {
      id: value?.cardPaymentType?.id,
      name: value?.cardPaymentType?.name,
    };
    this.source = {
      id: value?.source?.id,
      name: value?.source?.name,
    };
    this.vmModel = {
      id: value?.vmModel?.id,
      name: value?.vmModel?.name,
    };
    this.timeZone = value?.timeZone;
    this.serial = value?.serial;
    this.company = value?.company;
    this.placement = value?.placement;
    this.comment = value?.comment;
    this.timeOfWork = value?.timeOfWork;
    this.equipment = value?.equipment;
    this.qrCode = value?.qrCode;
    // this.productMatrix = value?.productMatrix;
    this.productMatrixs = {
      id: value?.productMatrixs?.id,
      name: value?.productMatrixs?.name,
    };
    this.priceListTemplate = {
      id: value?.priceListTemplate?.id,
      name: value?.priceListTemplate?.name,
    };
    this.decimalPrecision = value?.decimalPrecision;
    this.isInLoyaltySystem = value?.isInLoyaltySystem;
    this.isInLeasing = value?.isInLeasing;
    this.isBonusEnabled = value?.isBonusEnabled;
    this.locationFormats = [
      {
        id: value?.locationFormats?.[0].id,
        locationFormat: {
          id: value?.locationFormats?.[0]?.locationFormat?.id,
          name: value?.locationFormats?.[0]?.locationFormat?.name,
        },
      },
    ];
  }
}

export class VendingMachineFormModel extends IVendingMachineBase {
  vmManufacturer: number;
  brand: number;
  cardPaymentType: number;
  source: number;
  vmModel: number;
  productMatrix: string;
  company: string;
  placement: string;
  number: string;
  timeOfWork: string;
  timeZone: number;
  equipment: number;
  decimalPrecision: number;
  comment: string;
  locationFormats: number[];
  priceList: string;
  isInLoyaltySystem: boolean;
  isInLeasing: boolean;
  isBonusEnabled: boolean;
  qrCode: string;
  constructor(value?: VendingMachineGetModel) {
    super(value);
    this.timeZone = value?.timeZone;
    this.vmManufacturer = value?.vmManufacturer?.id;
    this.brand = value?.brand?.id;
    this.cardPaymentType = value?.cardPaymentType?.id
    this.source = value?.source.id;
    this.vmModel = value?.vmModel?.id;
    this.productMatrix = value?.productMatrixs?.id;
    this.company = value?.company.id;
    this.equipment = value?.equipment;
    this.placement = value?.placement;
    this.comment = value?.comment;
    this.timeOfWork = value?.timeOfWork;
    this.decimalPrecision = value?.decimalPrecision;
    this.locationFormats = value?.locationFormats?.map((x) => x.locationFormat.id);
    this.priceList = value?.priceListTemplate?.id;
    this.isInLoyaltySystem = value?.isInLoyaltySystem;
    this.isInLeasing = value?.isInLeasing;
    this.isBonusEnabled = value?.isBonusEnabled;
    this.qrCode = value?.qrCode;
  }
}
export class VendingMachineSendModel extends IVendingMachineBase {
  vmManufacturer: {
    entity: EEntityList.vm_manufacturer;
    id: number;
  } | null;
  brand: {
    entity: EEntityList.brand;
    id: number;
  } | null;
  cardPaymentType: {
    entity: EEntityList.card_payment_type;
    id: number;
  } | null;
  source: {
    entity: EEntityList.source;
    id: number;
  } | null;
  productMatrix: {
    entity: EEntityList.product_matrix;
    id: string;
  } | null;
  priceList: {
    entity: EEntityList.vending_machine_price_list;
    id: string;
    productMatrix: string;
  } | null;
  vmModel: {
    entity: EEntityList.vm_model;
    id: number;
  } | null;
  equipment: number;
  placement: string;
  comment: string;
  timeOfWork: string;
  locationFormats: any[];
  decimalPrecision: number;
  isInLoyaltySystem: boolean;
  isInLeasing: boolean;
  isBonusEnabled: boolean;
  timeZone: number;
  qrCode: string;
  constructor(value: VendingMachineFormModel) {
    super(value);
    this.timeZone = value.timeZone;
    this.decimalPrecision = value.decimalPrecision;
    this.placement = value.placement;
    this.comment = value.comment;
    this.timeOfWork = value.timeOfWork;
    this.isInLoyaltySystem = value.isInLoyaltySystem;
    this.isInLeasing = value.isInLeasing;
    this.isBonusEnabled = value.isBonusEnabled;
    this.qrCode = value.qrCode;
    this.vmManufacturer = !isNaN(value.vmManufacturer) && value.vmManufacturer !== null
      ? {
          id: value.vmManufacturer,
          entity: EEntityList.vm_manufacturer,
        }
      : null;
    this.brand = !isNaN(value.brand)
      ? {
          id: value.brand,
          entity: EEntityList.brand,
        }
      : null;
    this.cardPaymentType = !isNaN(value.cardPaymentType)
      ? {
          id: value.cardPaymentType,
          entity: EEntityList.card_payment_type,
        }
      : null;
    this.source = !isNaN(value.source)
      ? {
          id: value.source,
          entity: EEntityList.source,
        }
      : null;
    this.productMatrix = value.productMatrix
      ? {
          id: value.productMatrix,
          entity: EEntityList.product_matrix,
        }
      : null;
    this.priceList = value.priceList
      ? {
          id: value.priceList,
          productMatrix: value.productMatrix,
          entity: EEntityList.vending_machine_price_list,
        }
      : null;
    this.vmModel = value.vmModel
      ? {
          id: value.vmModel,
          entity: EEntityList.vm_model,
        }
      : null;
    this.equipment = value.equipment ?? 0;
    this.locationFormats = value.locationFormats
      ? value.locationFormats.map((x) => ({
          entity: EEntityList.vm_location_formats,
          locationFormat: { entity: EEntityList.location_format, id: x },
        }))
      : null;
  }

  // @ts-ignore
  private getBitMask(val): number {
    let bit;
    for (let i in val) {
      if (val.hasOwnProperty(i) && val[i]) {
        // @ts-ignore
        bit = bit | EEquipmentType[i];
      }
    }
    return bit;
  }
}

export class VendingMachineCoordinateModel {
  lat: number;
  lng: number;
  radius: number;
  updatetime: string;
}

export class VmLocationFormat {
  id: number;
  locationFormat: LocationFormat;
}

export class LocationFormat {
  id: number;
  name: string;
}

export enum EVMWorkMode {
  SLAVE,
  STAND,
}
